import * as React from 'react';
import ScrollToTop from 'components/ScrollToTop';

import { BrowserRouter as Router } from 'react-router-dom';

import { useAuth } from 'containers/Auth/context';
import { isNull } from 'utils/helpers';

const AuthentictedApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AuthentictedApp" */ 'containers/App/AuthentictedApp'
  ),
);

const UNAuthenticatedApp = React.lazy(() =>
  import(
    /* webpackChunkName: "UNAuthenticatedApp" */ 'containers/App/UNAuthenticatedApp'
  ),
);

function App() {
  const auth = useAuth();
  return (
    <Router>
      <React.Suspense fallback={<div>{null}</div>}>
        <ScrollToTop updateWithPath />
        {!isNull(auth?.token) ? <AuthentictedApp /> : <UNAuthenticatedApp />}
      </React.Suspense>
    </Router>
  );
}

export default App;

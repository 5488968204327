import * as React from 'react';
// import { useTablePagination } from '../TableUtils/TablePagination';

const TableStateContext = React.createContext();
const TableDispatchContext = React.createContext();

const intitalTableState = {
  pagination: {
    recordsPerPage: 15, // pageSize
    page: 1, // pageIndex
    sortingProperty: 'created_at',
    sortedAscending: 'no',
  },
};

function TableReducer(state, action) {
  switch (action.type) {
    case 'INITIALIZE': {
      return { ...state, ...action?.payload };
    }
    case 'NEXT_PAGE': {
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          page: state?.pagination?.page + 1,
        },
      };
    }
    case 'PREVIOUS_PAGE': {
      return {
        ...state,
        pagination: {
          ...state?.pagination,
          page: state?.pagination?.page > 1 ? state?.pagination?.page - 1 : 1,
        },
      };
    }
    case 'PAGE_RESET': {
      return {
        ...intitalTableState,
      };
    }
    case 'SET_PAGE_SIZE': {
      return {
        ...state,
        pagination: { ...state?.pagination, recordsPerPage: action?.payload },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TableProvider({ children }) {
  // const { page } = useTablePagination();
  const [state, dispatch] = React.useReducer(TableReducer, {
    ...intitalTableState,
    // page,
  });
  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        {children}
      </TableDispatchContext.Provider>
    </TableStateContext.Provider>
  );
}

function useTableState() {
  const context = React.useContext(TableStateContext);
  if (context === undefined) {
    throw new Error('useTableState must be used within a ContextProvider');
  }
  return context;
}

function useTableDispatch() {
  const context = React.useContext(TableDispatchContext);
  if (context === undefined) {
    throw new Error('useTableDispatch must be used within a ContextProvider');
  }
  return context;
}

const goToPage = paginatedData => {
  if (!paginatedData?.current_page) {
    return 1;
  }
  return paginatedData?.current_page + 1;
};

export {
  TableProvider,
  useTableState,
  useTableDispatch,
  TableStateContext,
  goToPage,
};

import * as React from 'react';
import { Helmet } from 'react-helmet';

export default function Head() {
  const faviconSrc =
    process.env.NODE_ENV === 'production' ? '/favicon.ico' : '/favicon-dev.ico';

  window.intercomSettings = {
    app_id: 'ovbo7487',
  };
  if (process.env.NODE_ENV === 'production') {
    (function() {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function() {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/ovbo7487';
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  return (
    <Helmet>
      <link
        rel='icon'
        type='image/png'
        // query strings, little hack to clear ico browser cache
        href={`${process.env.PUBLIC_URL}${faviconSrc}?=2`}
      />
    </Helmet>
  );
}

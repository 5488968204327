import SimpleCrypto from 'simple-crypto-js';
import React from 'react';

import { useTableDispatch } from 'components/Tables/TableContext/TableContext';
import { toLowercase } from '../stringUtils';

const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY;
export const HOST_APP_ADDRESS = process.env.REACT_APP_HOST_ADDRESS;

export function isNull(variable) {
  const oneOfIsNull = [undefined, null, 'null', 'undefined', ''];
  const transformedVariable =
    typeof variable === 'string' ? toLowercase(variable) : variable;
  return oneOfIsNull.some(item => item === transformedVariable);
  // return (
  //   variable === undefined ||
  //   variable === null ||
  //   variable === '' ||
  //   variable === 'null' ||
  //   variable === 'Null' ||
  //   variable === 'NULL' ||
  //   variable === 'undefined' ||
  //   variable === 'Undefined'
  // );
}

/**
 * @name WPBTOA
 * @category String Helpers
 * @description Creates a base-64 encoded ASCII string from a String object in which each character in the string is treated as a byte of binary data.
 * @param {String} str - the string to be encoded
 */

export function WPBTOA(str) {
  if (arguments.length !== 1) {
    throw new TypeError(
      ` WPATOB requires 1 argument of type String, but ${arguments.length} present`,
    );
  }
  const value = str.toString();
  return Buffer.from(value, 'binary').toString('base64');
}

/**
 * @name WPATOB
 * @category String Helpers
 * @description Decodes a string of data which has been encoded using base-64 encoding.
 * @param {String} str - the base-64 encoded string to be decoded
 */

export function WPATOB(str) {
  if (arguments.length !== 1) {
    throw new TypeError(
      ` WPATOB requires 1 argument of type String, but ${arguments.length} present`,
    );
  }
  const value = str.toString();
  return Buffer.from(value, 'base64').toString('binary');
}

export function createSelectOptions(data = [], value, label) {
  // data is an array of objects of the options you have
  // value is the key of what you want as option in your data set and the same applies for label
  if (Array.isArray(data)) {
    const options = data.map(option => ({
      value: option[value || 'id'],
      label: option[label || 'name'],
      data: option,
    }));
    return options;
  }
  return [];
}

export function createActions(params) {
  // THIS function needs to throw errors when things fail
  let actions = [];
  actions = params?.filter(Boolean);
  return actions;
}

export function encrypt(plainText) {
  if (isNull(plainText)) {
    return plainText;
  }
  const simpleCrypto = new SimpleCrypto(CRYPTO_KEY);
  try {
    return simpleCrypto.encrypt(plainText);
  } catch (e) {
    return plainText;
  }
}

export function decrypt(encryptedText) {
  if (isNull(encryptedText)) {
    return encryptedText;
  }
  const simpleCrypto = new SimpleCrypto(CRYPTO_KEY);
  try {
    return simpleCrypto.decrypt(encryptedText);
  } catch (e) {
    return encryptedText;
  }
}

export function openInNewTab(url, params = null) {
  if (params) {
    url += '?';
    Object.keys(params).forEach(paramKey => {
      url += `${paramKey}=${params[paramKey]}&`;
    });
  }
  const win = window.open(url, '_blank');
  if (win && win.hasOwnProperty('focus') && typeof win.focus === 'function') {
    win.focus();
  }
}

/**
 * @name clearLocalStorageAuthKeys
 * @category Auth Helpers
 * @description Clears all auth related localStorage Keys and datauseTableDispatch
 * @param
 */

export function clearLocalStorageAuthKeys() {
  localStorage.removeItem('SWITCHED_COUNTRY_KEY');
  localStorage.removeItem('AUTH_SWITCHED_COMPANY');
}

export const usePageReset = () => {
  const dispatch = useTableDispatch();
  React.useEffect(() => {
    dispatch({ type: 'PAGE_RESET' });
  }, []);
};

export function nodeIsTruncated(el) {
  return el?.scrollWidth > el?.clientWidth;
}

export function downLoadFromBinary({ file, name, extension = 'txt' }) {
  const fileName = isNull(name) ? 'download' : name?.split(' ').join('_');
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}_${Date.now()}.${extension}`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

/**
 * @name oneItemIsNullish
 * @category String Helpers
 * @description Checks if one of the items provided is Nullish.
 * @param {String} str - the string to be encoded
 */
export function oneItemIsNullish(...args) {
  if (args?.length < 1) {
    throw new TypeError(
      ` oneItemIsNullish requires atleast 1 argument, but ${arguments.length} present`,
    );
  }
  const hasNullishItem = args.some(item => isNull(item));
  return hasNullishItem;
}

/**
 * @name convertToBoolean
 * @category String Helpers
 * @description converst to boolean.
 * @param {String} str - the string to be encoded
 */

export function convertToBoolean(value) {
  if (arguments.length !== 1) {
    throw new TypeError(
      ` convertToBoolean requires 1 argument but ${arguments.length} present`,
    );
  }

  const valueIsTruthy = [1, '1', 'true'].some(v => v === value);
  if (valueIsTruthy) {
    return true;
  }
  return false;
}

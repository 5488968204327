import { isDark } from '@chakra-ui/theme-tools';
import appTheme from 'styles/theme';

export default function randomColorFromString(str = '', opacity = 1) {
  let hash = 0;
  if (str.length === 0) return hash.toString();
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }
  const rgb = [0, 0, 0];
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
}

export function blendColors(name) {
  const bgColor = name ? randomColorFromString(name, '0.25') : 'gray.400';

  let color = randomColorFromString(name);

  const isBgDark = isDark(bgColor)(appTheme);
  if (!isBgDark) color = 'gray.800';
  return { bgColor, color };
}

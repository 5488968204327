export default `
    @font-face {
      font-family: 'Aller';
      font-style: normal;
      font-weight: 300;
      font-display: optional;
      src: url('/fonts/Aller/Aller_Light.ttf') format('truetype');
      unicode-range: U+A,U+20-23,U+25-3B,U+3D,U+3F-59,U+5B,U+5D,U+61-7B,U+7D,U+A0,U+A9,U+2013,U+2014,U+2018,U+2019,U+201C,U+2026;
    }
    @font-face {
      font-family: 'Aller';
      font-style: normal;
      font-weight: 400;
      font-display: optional;
      src: url('/fonts/Aller/Aller_Regular.ttf') format('truetype');
      unicode-range: U+A,U+20-23,U+25-3B,U+3D,U+3F-59,U+5B,U+5D,U+61-7B,U+7D,U+A0,U+A9,U+2013,U+2014,U+2018,U+2019,U+201C,U+2026;
    }
    @font-face {
      font-family: 'Aller';
      font-style: normal;
      font-weight: 700;
      font-display: optional;
      src: url('/fonts/Aller/Aller_Bold.ttf') format('truetype');
      unicode-range: U+A,U+20-23,U+25-3B,U+3D,U+3F-59,U+5B,U+5D,U+61-7B,U+7D,U+A0,U+A9,U+2013,U+2014,U+2018,U+2019,U+201C,U+2026;
    }
  `;

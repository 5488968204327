export function toUppercase(word) {
  const str = word || '';
  return str?.toUpperCase();
}
export function toLowercase(word) {
  const str = word || '';
  return str?.toLowerCase().replace(/_/g, ' ');
}

export const onNumberKeyDownValidation = e =>
  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

export const onDecimalKeyDownValidation = e =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const onPaybillNoKeyDownValidation = e =>
  ['e', 'E', '-', '.', '$'].includes(e.key) && e.preventDefault();

export function removeUnderscoreFromString(str = '') {
  return str?.replace(/_/g, ' ');
}

export function hyphenateString(str = '') {
  return str?.replace(/\s/g, '-')?.toLowerCase();
}

export function convertToBoolean(param) {
  if (param === false || param === 0 || param === '0') {
    return false;
  }
  if (param === true || param === 1 || param === '1') {
    return true;
  }
  return false;
}

export const strictCurrencyFormatter = num => {
  if (isNaN(num)) return '0.00';
  const number = Number(num);
  return new Intl.NumberFormat('en-KE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })?.format(number);
};
